import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import style from './BlockYeuCauV2.module.less';
import { Col, DatePicker, Input, Row, Select, Space, Table, Button, Modal, notification } from 'antd';
import { DeleteOutlined, PlusOutlined, MinusOutlined, SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import cn from 'classnames';
import moment from 'moment';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { sumBy, mean, uniqBy } from 'lodash';
import TextArea from 'antd/es/input/TextArea';
import { keys } from '../../../../../constants';
import { localGet } from '../../../../../helpers';

const MAX_ROLL_OPTION = 100;

const MAX_NEGATIVE_WIDTH_TAPE = -5;

export const ORDER_OPTION = [
    {
        value: 'D',
        label: 'Thường',
    },
    {
        value: 'DB',
        label: 'Đặc biệt',
    },
];

const BlockYeuCauV2 = ({
    data,
    addBlock,
    editBlock,
    deleteBlock,
    addRoll,
    editRoll,
    deleteRoll,
    deleteAllRoll,
    addStandard,
    deleteStandard,
    editStandard,
    rollIdSelected,
    blockIndex,
    error,
}) => {
    const { listRoll, listStandard, id: idBlock, workOrderDate, sizeThickness, sizeWidth, sizeWidthReal, weightAvg, md, positionRoll1, positionRoll2, catDau } = data;
    const [modal, contextHolder] = Modal.useModal();
    const rollQty = listRoll.filter(i => !!i.rollId).length;

    // const errorCuon = errors.includes(idCuon);
    const _ref = useRef(null);

    // const [importDate, set_importDate] = useState('');
    const rollMaster = useSelector(state => state.app.listRollManufacture);

    // filter input để tìm cuộn dễ hơn
    const [filterThickness, set_filterThickness] = useState('');
    const [filterWidth, set_filterWidth] = useState('');

    useEffect(() => {
        //fill giá trị filter từ local storage
        const savedForm = localGet(keys.yeucau_bangxe);
        if (savedForm && savedForm[blockIndex]) {
            //console.log('Block index', savedForm[blockIndex]);
            const { sizeThickness, sizeWidth } = savedForm[blockIndex];
            if (sizeThickness && sizeWidth) {
                set_filterThickness(sizeThickness);
                set_filterWidth(sizeWidth);
            }
        }
    }, []);

    const [errFilter, set_errFilter] = useState(false);
    const [invalidVia, set_invalidVia] = useState(false);

    // list option cuộn trong thanh dropdown (Max default 100)
    const rollOption = useMemo(
        () => rollMaster?.filter(item => (!sizeThickness || item.sizeDepth.toString() == sizeThickness) && (!sizeWidth || item.sizeWidth.toString() == sizeWidth)) || [],
        [rollMaster, sizeWidth, sizeThickness],
    );
    // console.log('Roll option', rollOption);

    const onPressFilter = () => {
        var arrThickWidth = listRoll.filter(i => !!i.rollId).map(i => i.thickWidth);
        var filterSize = filterThickness + 'x' + filterWidth;
        console.log('Depth', filterSize);
        console.log('arrThickWidth', arrThickWidth);
        if (!filterWidth || !filterThickness) {
            set_errFilter(true);
            notification.error({ message: 'Nhập đầy đủ dày/rộng' });
            return;
        }
        if (rollQty > 0 && !arrThickWidth.includes(filterSize)) {
            modal.confirm({
                title: 'Xác nhận thay đổi dày rộng',
                icon: <ExclamationCircleOutlined />,
                content: 'Sau khì thay đổi dày/rộng, toàn bộ cuộn có kích thước dày/rộng cũ sẽ bị xoá đi khỏi danh sách xẻ. Tiếp tục?',
                okText: 'OK',
                okType: 'danger',
                onOk: () => changeThickWidth(true),
                cancelText: 'Đóng',
            });
        } else changeThickWidth();
    };
    const changeThickWidth = (reset = false) => {
        if (reset) deleteAllRoll(idBlock);

        editBlock(idBlock, 'sizeWidth')(filterWidth);
        editBlock(idBlock, 'sizeThickness')(filterThickness);
        editBlock(idBlock, 'sizeWidthReal')((Number(filterWidth) + 10).toString());
        editBlock(idBlock, 'md')('D');
    };

    //chọn cuộn sẽ tự động fill thuộc tính của cuộn
    const onSelectRoll = (id, rollId) => {
        var idCuon = idBlock + '-' + id;
        const roll = rollOption.find(item => item.id == rollId);
        if (!roll) return;

        var weightArr = listRoll
            .filter(i => !!i.rollId)
            .map(i => Number(i.weight))
            .concat(Number(roll.netWeight));
        editBlock(idBlock, 'weightAvg')(mean(weightArr).toFixed(2));

        editRoll(idCuon, 'rollId')(rollId);
        editRoll(idCuon, 'steelType')(roll.steelType || '');
        editRoll(idCuon, 'steelGrade')(roll.steelGrade || '');
        editRoll(idCuon, 'weight')(roll.netWeight || '');
        editRoll(idCuon, 'importDate')(roll.importDate || '');
        editRoll(idCuon, 'thickWidth')(roll.sizeDepth + 'x' + roll.sizeWidth);
        editRoll(idCuon, 'ts')(roll.ts || '');

        //auto set dày/rộng của block nếu như chưa có
        if (!sizeThickness && !sizeWidth) {
            //console.log("Roll found", roll)
            set_filterThickness(roll.sizeDepth?.toString() || '');
            set_filterWidth(roll.sizeWidth?.toString() || '');
            editBlock(idBlock, 'sizeWidth')(roll.sizeWidth?.toString() || '');
            editBlock(idBlock, 'sizeThickness')(roll.sizeDepth?.toString() || '');
            editBlock(idBlock, 'sizeWidthReal')((Number(roll.sizeWidth) + 10).toString());
            editBlock(idBlock, 'md')('D');
        }
    };

    //list thành phẩm (Lấy master từ server)
    const standardMaster = useSelector(state => state.app.listMasterStandard);
    const thickMaster = useSelector(state => state.app.listThickStandard);
    const standardMasterSpecial = useSelector(state => state.app.listMasterStandardSpecial);
    const thickMasterSpecial = useSelector(state => state.app.listThickStandardSpecial);

    const calc_via_width = useMemo(() => {
        var value = Number(sizeWidth) - sumBy(listStandard, i => Number(i.sizeWidthTape) * Number(i.stripNumber));
        return isNaN(value) || value == Infinity ? '0' : value.toFixed(2);
    }, [sizeWidth, listStandard]);
    useEffect(() => {
        editBlock(idBlock, 'sizeWidthVia')(Number(calc_via_width));
        var invalid = Number(calc_via_width) < MAX_NEGATIVE_WIDTH_TAPE;
        editBlock(idBlock, 'errorVia')(invalid);
        if (invalid) {
            set_invalidVia(true);
        } else set_invalidVia(false);
    }, [calc_via_width]);

    const calc_via_weight = useMemo(() => {
        var value = (Number(weightAvg) * rollQty * (Number(calc_via_width) - Number(sizeWidth) + Number(sizeWidthReal))) / Number(sizeWidthReal);
        return isNaN(value) || value == Infinity ? '0' : value.toFixed(2);
    }, [calc_via_width, sizeWidth, sizeWidthReal, weightAvg, rollQty]);
    useEffect(() => {
        editBlock(idBlock, 'mass')(Number(calc_via_weight));
    }, [calc_via_weight]);

    const calc_via_total = useMemo(() => {
        if (!catDau) return calc_via_weight;
        var length = Math.round((Number(weightAvg) * 99) / (Number(sizeThickness) * Number(sizeWidth) * 0.785));
        var viaWeight = Math.round((length * 0.785 * Number(catDau) * Number(sizeThickness)) / 1000);
        var totalVia = Number(calc_via_weight) + viaWeight;
        return Math.round(totalVia);
    }, [catDau, weightAvg, sizeThickness, sizeWidth, calc_via_weight]);
    useEffect(() => {
        editBlock(idBlock, 'totalVia')(Number(calc_via_total));
    }, [calc_via_total]);

    const calc_via_rate = useMemo(() => {
        var val = (calc_via_total / (Number(weightAvg) * rollQty)) * 100;
        return isNaN(val) || val == Infinity ? '0' : val.toFixed(2);
    }, [calc_via_total, weightAvg, rollQty]);
    useEffect(() => {
        editBlock(idBlock, 'ratioTrash')(Number(calc_via_rate));
    }, [calc_via_rate]);

    const columns_cuon = [
        {
            title: 'Mã cuộn',
            dataIndex: 'rollId',
            key: 'rollId',
            width: 170,
            render: (value, record, index) => {
                const otherRoll = rollIdSelected.filter(i => i != value);
                const options = rollOption.filter(i => !otherRoll.includes(i.id)).slice(0, MAX_ROLL_OPTION);
                return (
                    <Select
                        id={`block_${blockIndex}-roll_${index}`}
                        className={cn(style['table-select'], error && value == '' && style['warning-select'])}
                        options={options}
                        onChange={value => onSelectRoll(record.id, value)}
                        value={value}
                        optionFilterProp="label"
                        dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        showSearch
                        listHeight={160}
                        //allowClear
                    />
                );
            },
        },
        {
            title: 'TS',
            dataIndex: 'ts',
            key: 'ts',
            width: 100,
            render: (value, record) => <Input className={style['table-input']} value={value} onChange={editRoll(`${idBlock}-${record.id}`, 'ts', 'numeric')} />,
        },
        {
            title: 'Dày x rộng(mm)',
            dataIndex: 'thickWidth',
            key: 'thickWidth',
            width: 125,
            render: (value, record) => <Input className={style['table-input']} value={value} disabled />,
        },
        {
            title: 'Khối lượng(kg)',
            dataIndex: 'weight',
            key: 'weight',
            width: 120,
            render: (value, record) => <Input className={style['table-input']} value={value} disabled />,
        },
        {
            title: 'Ngày nhập kho',
            dataIndex: 'importDate',
            key: 'importDate',
            width: 120,
            render: (value, record) => <Input className={style['table-input']} disabled value={value} />,
        },
        {
            title: 'Loại thép',
            dataIndex: 'steelType',
            key: 'steelType',
            width: 120,
            render: (value, record) => <Input className={style['table-input']} disabled value={value} />,
        },
        {
            title: 'Mác thép',
            dataIndex: 'steelGrade',
            key: 'steelGrade',
            width: 120,
            render: (value, record) => <Input className={style['table-input']} disabled value={value} />,
        },

        {
            title: '',
            key: 'option',
            width: 80,
            align: 'center',
            render: (value, item, index) => (
                <Space>
                    <div className={style['table-button']} onClick={() => addRoll(idBlock)}>
                        <PlusOutlined style={{ color: '#2c3782', fontSize: '12px' }} />
                    </div>
                    <div className={style['table-button']} onClick={() => deleteRoll(`${idBlock}-${item.id}`)}>
                        <MinusOutlined style={{ color: '#2c3782', fontSize: '12px' }} />
                    </div>
                </Space>
            ),
        },
    ];

    const columns_xe = [
        {
            title: 'Đơn hàng',
            dataIndex: 'orderCode',
            key: 'orderCode',
            width: 110,
            align: 'center',
            render: (text, item, index) => {
                const onChangeDonHang = value => {
                    editStandard(`${idBlock}-${item.id}`, 'orderCode')(value);
                    if (value != text) {
                        editStandard(`${idBlock}-${item.id}`, 'thicknessProduct')('');
                        editStandard(`${idBlock}-${item.id}`, 'thicknessMin')('');
                        editStandard(`${idBlock}-${item.id}`, 'sizeWidthTape')('');
                        editStandard(`${idBlock}-${item.id}`, 'typeProduct')('');
                    }
                };
                return (
                    <Select
                        id={`block_${blockIndex}-standard_${index}`}
                        className={cn(style['table-select'], error && text == '' && style['warning-select'])}
                        options={ORDER_OPTION}
                        onChange={onChangeDonHang}
                        value={text}
                        optionFilterProp="label"
                        dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
                    />
                );
            },
        },
        {
            title: 'Dày sp(mm)*',
            dataIndex: 'thicknessProduct',
            key: 'thicknessProduct',
            width: 90,
            align: 'center',
            render: (text, item, index) => {
                var sMaster = item.orderCode == 'DB' ? standardMasterSpecial : standardMaster;
                var onSelect = value => {
                    if (!!value) {
                        editStandard(`${idBlock}-${item.id}`, 'thicknessProduct')(value);
                        editStandard(`${idBlock}-${item.id}`, 'thicknessMin')((Number(value) * 0.95).toFixed(2));
                        var listBody = sMaster?.find(i => i.value == item.typeProduct)?.lstBody || [];
                        // console.log('List body', listBody);
                        if (listBody.length > 0) {
                            var do_rong_xe = listBody.find(i => i.specificSize.toString() == value)?.sizeThickness || '';
                            editStandard(`${idBlock}-${item.id}`, 'sizeWidthTape')(do_rong_xe);
                        }
                    }
                };
                return (
                    <Select
                        id={`block_${blockIndex}-standard_${index}`}
                        className={cn(style['table-select'], error && text == '' && style['warning-select'])}
                        options={item.orderCode == 'DB' ? thickMasterSpecial : thickMaster}
                        onChange={onSelect}
                        value={text}
                        optionFilterProp="label"
                        dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        showSearch
                        listHeight={160}
                        onSearch={onSelect}
                    />
                );
            },
        },
        {
            title: 'Dày MIN(mm)',
            dataIndex: 'thicknessMin',
            key: 'thicknessMin',
            width: 90,
            align: 'center',
            render: (text, item, index) => {
                return (
                    <Input
                        className={cn(style['table-input'])}
                        value={item.thicknessMin || ''}
                        disabled
                        // onChange={onChangeText}
                    />
                );
            },
        },
        {
            title: 'Loại sản phẩm(mm)*',
            dataIndex: 'typeProduct',
            key: 'typeProduct',
            width: 100,
            align: 'center',
            render: (text, item, index) => {
                var sMaster = item.orderCode == 'DB' ? standardMasterSpecial : standardMaster;
                var onSelect = value => {
                    if (!!value) {
                        var listBody = sMaster?.find(i => i.value == value)?.lstBody || [];
                        var do_rong_xe = '';
                        if (listBody.length > 0) {
                            do_rong_xe = listBody.find(i => i.specificSize.toString() == item.thicknessProduct)?.sizeThickness || '';
                        }
                        editStandard(`${idBlock}-${item.id}`, 'sizeWidthTape')(do_rong_xe);
                        editStandard(`${idBlock}-${item.id}`, 'typeProduct')(value);
                    }
                };
                return (
                    <Select
                        className={cn(style['table-select'], error && text == '' && style['warning-select'])}
                        options={sMaster || []}
                        onChange={onSelect}
                        value={text}
                        optionFilterProp="label"
                        dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
                        filterOption={(input, option) => {
                            console.log(input);
                            console.log(option);
                            return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
                        }}
                        showSearch
                        listHeight={160}
                        onSearch={onSelect}
                    />
                );
            },
        },
        {
            title: 'Rộng(mm)*',
            dataIndex: 'sizeWidthTape',
            key: 'sizeWidthTape',
            width: 80,
            align: 'center',
            render: (text, item, index) => {
                // var err_xe = errors.includes(`${idCuon}-${item.id_xe}`);
                var err_xe = false;
                const onChangeText = ({ target }) => {
                    var widthNum = Number(target.value.replace(/[^0-9.]/g, '')) || 0;
                    var stripNum = Number(item.stripNumber) || 0;
                    editStandard(`${idBlock}-${item.id}`, 'sizeWidthTape', 'numeric')(target.value);
                    editStandard(`${idBlock}-${item.id}`, 'totalWidth')((widthNum * stripNum).toFixed(2));
                };
                return <Input className={cn(style['table-input'], error && text == '' && style['warning-input'])} value={text} onChange={onChangeText} />;
            },
        },
        {
            title: 'Số dải*',
            dataIndex: 'stripNumber',
            align: 'center',
            key: 'stripNumber',
            width: 70,
            render: (text, item, index) => {
                var err_xe = false;
                const onChangeText = ({ target }) => {
                    var stripNum = Number(target.value.replace(/[^0-9.]/g, '')) || 0;
                    var widthNum = Number(item.sizeWidthTape) || 0;
                    editStandard(`${idBlock}-${item.id}`, 'stripNumber')(stripNum.toString());
                    editStandard(`${idBlock}-${item.id}`, 'totalWidth')((widthNum * stripNum).toFixed(2));
                };
                return <Input className={cn(style['table-input'], error && text == '' && style['warning-input'])} value={text} onChange={onChangeText} />;
            },
        },
        {
            title: 'Tổng độ rộng(mm)',
            dataIndex: 'totalWidth',
            align: 'center',
            key: 'totalWidth',
            width: 90,
            render: (text, item, index) => <Input disabled className={style['table-input']} value={text} />,
        },
        {
            title: 'Khối lượng tính(kg)',
            // dataIndex: 'weightCal',
            align: 'center',
            key: 'weightCal',
            width: 90,
            render: (text, item, index) => {
                var numValue = (Number(weightAvg) * rollQty * Number(item.sizeWidthTape) * Number(item.stripNumber)) / Number(sizeWidthReal);
                return <Input disabled className={cn(style['table-input'])} value={isNaN(numValue) || numValue == Infinity ? 0 : numValue.toFixed(2)} />;
            },
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            key: 'note',
            width: 200,
            align: 'center',
            render: (text, item, index) => (
                <TextArea
                    style={{ padding: '3px' }}
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    className={style['table-input']}
                    value={item.note}
                    onChange={editStandard(`${idBlock}-${item.id}`, 'note')}
                />
            ),
        },
        {
            title: '',
            key: 'option',
            width: '0px',
            align: 'center',
            render: (value, item, index) => (
                <Space>
                    <div className={style['table-button']} onClick={() => addStandard(idBlock)}>
                        <PlusOutlined style={{ color: '#2c3782', fontSize: '12px' }} />
                    </div>
                    <div className={style['table-button']} onClick={() => deleteStandard(`${idBlock}-${item.id}`)}>
                        <MinusOutlined style={{ color: '#2c3782', fontSize: '12px' }} />
                    </div>
                </Space>
            ),
        },
    ];

    // useImperativeHandle(ref, () => ({
    //     scrollTo() {
    //         //console.log('Scroll into this view!');
    //         _ref.current?.scrollIntoView({ behavior: 'smooth' });
    //     },
    // }));

    //khi thêm mới cuộn tự scroll xuống
    // useEffect(() => {
    //     _ref.current?.scrollIntoView({ behavior: 'smooth' });
    // }, []);

    return (
        <div className={[style['main']]} ref={_ref}>
            <Row style={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', marginBottom: '20px' }}>
                <Col span={2} className={style['block-title']}>
                    Ngày lệnh
                </Col>
                <Col span={4} className={cn(style['block-title'])}>
                    <DatePicker
                        id={`block_${blockIndex}-workOrderDate`}
                        format={'DD/MM/YYYY'}
                        className={cn(style['block-input'], error && workOrderDate == '' && style['warning-input'])}
                        placeholder=""
                        value={!!workOrderDate && workOrderDate !== '' ? dayjs(workOrderDate, 'DD/MM/YYYY') : undefined}
                        onChange={(date, dateStr) => editBlock(idBlock, 'workOrderDate')(dateStr)}
                    />
                </Col>
                <Col span={12}></Col>
                <Col span={6}>
                    <Row style={{ gap: '10px', flexDirection: 'row-reverse' }}>
                        <Button className={cn(style['filter-button'], style['delete-button'])} style={{ height: '34px' }} onClick={() => deleteBlock(idBlock)}>
                            <DeleteOutlined style={{ color: 'white' }} />
                            Xoá lệnh
                        </Button>
                        <Button className={cn(style['filter-button'], style['add-button'])} style={{ height: '34px' }} onClick={addBlock}>
                            <PlusOutlined style={{ color: 'white' }} />
                            Thêm lệnh
                        </Button>
                    </Row>
                </Col>
            </Row>

            <h4 style={{ color: 'red' }}>Chọn tôn cuộn</h4>

            <Row className={style['row-input']} style={{ rowGap: '30px' }}>
                <Col span={2} className={style['filter-title']}>
                    {'Dày x rộng(mm)'}
                </Col>
                <Col span={4}>
                    <Row style={{ justifyContent: 'space-between', width: '85%', alignItems: 'center' }}>
                        <Input
                            className={cn(style['small-input'], (errFilter || error) && filterThickness == '' && style['warning-input'])}
                            value={filterThickness}
                            onChange={({ target }) => set_filterThickness(target.value.replace(/[^0-9.]/g, ''))}
                        />
                        <h5>x</h5>
                        <Input
                            id={`block_${blockIndex}-sizeWidth`}
                            className={cn(style['small-input'], (errFilter || error) && filterWidth == '' && style['warning-input'])}
                            value={filterWidth}
                            onChange={({ target }) => set_filterWidth(target.value.replace(/[^0-9.]/g, ''))}
                        />
                    </Row>
                </Col>
                <Col span={3}>
                    <Button className={style['filter-button']} style={{ width: '80%' }} onClick={onPressFilter}>
                        <SearchOutlined style={{ color: 'white' }} />
                        Tìm kiếm
                    </Button>
                </Col>
                <Col span={15} />
                <Col span={2} className={style['filter-title']}>
                    {'Rộng thực tế(mm)'}
                </Col>
                <Col span={4}>
                    <Input
                        className={cn(style['filter-input'], error && sizeWidthReal == '' && style['warning-input'])}
                        value={sizeWidthReal}
                        onChange={editBlock(idBlock, 'sizeWidthReal', 'numeric')}
                    />
                </Col>
                <Col span={2} className={style['filter-title']}>
                    {'M/D'}
                </Col>
                <Col span={4}>
                    <Input className={cn(style['filter-input'])} value={md} onChange={editBlock(idBlock, 'md')} />
                </Col>
                <Col span={2} className={style['filter-title']}>
                    {'Vị trí cuộn(H)'}
                </Col>
                <Col span={4}>
                    <Input className={cn(style['filter-input'])} value={positionRoll1} onChange={editBlock(idBlock, 'positionRoll1')} />
                </Col>
                <Col span={2} className={style['filter-title']}>
                    {'Vị trí cuộn(K)'}
                </Col>
                <Col span={4}>
                    <Input className={cn(style['filter-input'])} value={positionRoll2} onChange={editBlock(idBlock, 'positionRoll2')} />
                </Col>

                <Col span={2} className={style['filter-title']}>
                    {'Cắt đầu'}
                </Col>
                <Col span={4}>
                    <Input className={cn(style['filter-input'])} value={catDau} onChange={editBlock(idBlock, 'catDau', 'numeric')} />
                </Col>
            </Row>

            <Table
                style={{ marginTop: '20px' }}
                columns={columns_cuon}
                dataSource={listRoll}
                className={cn(style['table'], error && (rollQty == 0 || uniqBy(listRoll, 'thickWidth').length > 1) && style['warning-input'])}
                rowKey="key"
                id={`block_${blockIndex}-rollTable`}
                scroll={{ x: sumBy(columns_cuon, 'width') }}
                pagination={false}
                //pagination={{ position: ['none', 'none'], pageSize: 500000 }}
            />

            <Row style={{ marginTop: '20px' }}>
                <Col span={4} className={style['filter-title']}>
                    Số lượng cuộn: {rollQty}
                </Col>
                <Col span={6} className={style['filter-title']}>
                    Khối lượng trung bình: {weightAvg || 0}kg
                </Col>
            </Row>

            <h4 style={{ color: 'red', marginTop: '25px' }}>Quy cách xẻ</h4>
            <Table
                columns={columns_xe}
                dataSource={listStandard}
                className={style['table']}
                rowKey="key"
                // ref={table}
                scroll={{ y: listStandard.length > 10 ? 580 : null }}
                pagination={false}
                //pagination={{ position: ['none', 'none'], pageSize: 500000 }}
            />
            <h4 style={{ color: 'red', marginTop: '20px' }}>{Number(calc_via_width) > 60 ? 'Rộng còn lại' : 'Via'}</h4>
            <Row className={style['row-input']}>
                <Col span={2} className={style['filter-title']}>
                    {'Rộng thực tế(mm)'}
                </Col>
                <Col span={4}>
                    <Input id={`block_${blockIndex}-widthVia`} disabled className={cn(style['filter-input'], invalidVia && style['warning-input'])} value={calc_via_width} />
                    <h5 style={{ color: 'red', marginTop: '5px' }}>{invalidVia ? 'Vượt quá tiêu chuẩn' : ''}</h5>
                </Col>
                <Col span={2} className={style['filter-title']}>
                    {'Khối lượng via(kg)'}
                </Col>
                <Col span={4}>
                    <Input disabled className={style['filter-input']} value={calc_via_weight} />
                </Col>
                <Col span={2} className={style['filter-title']}>
                    {'Tổng via(kg)'}
                </Col>
                <Col span={4}>
                    <Input disabled className={style['filter-input']} value={calc_via_total} />
                </Col>
                <Col span={2} className={style['filter-title']}>
                    Tỷ lệ phế
                </Col>
                <Col span={4}>
                    <Input disabled className={style['filter-input']} value={`${calc_via_rate}%`} />
                </Col>
            </Row>
            {contextHolder}
        </div>
    );
};

export default memo(BlockYeuCauV2);
