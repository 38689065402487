import React, { useRef, useState } from 'react';
import style from './themMoiNhapKho.module.less'
import { Button, DatePicker, Flex, Input, Select, Space, Table, notification } from 'antd';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { rid } from '../../../../helpers';
import AxiosService from '../../../../common/AxiosService';
import { apis, keys, paths } from '../../../../constants';
import { useHistory } from "react-router-dom";
import cn from 'classnames';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { appLoading, setAppProperties } from '../../../../ducks/slices/appSlice';
import dayjs from 'dayjs';
import { CONSIGNMENT, MISA_NAME_ACRONYM } from '../../../../constants/utils';
import { generateNewTypeOfSteel } from '../../../../utils/steelMasterType';
import _ from "lodash";
import { compareCurrentDate } from '../../../../utils/validate';

const ThemMoiNhapKho = (props) => {
    const [data, set_data] = useState([{...sampleInput, key: rid(), new: false}]);
    const [showWarning, set_showWarning] = useState(false)
    const [dateWarning, set_dateWarning] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();
    const table = useRef()
    const app = useSelector(state => state.app)

    const auth = useSelector(state => state.auth);

    const addRow = () => {
        set_data(current => current.concat([{...sampleInput, key: rid()}]))
        setTimeout(() => {
          table.current.scrollTo({index: data.length - 1 })
        },200)
    }

    const removeRow = (item_index) => {
        set_data(current => current.filter((item, index) => index !== item_index))
    }

    const duplicateRow = (item_index) => {
      set_data(current => current.concat(current[item_index]))
    }

    const onPressSave = () => {
        // set_showWarning(true)
        set_data(currentData => currentData.map(item => ({...item, new: false})))
        if(data.some(item => checkEmptyRecord(item))){
          console.log("Data",data)
          set_showWarning(true)
        }
        // else if(data.some(item => compareCurrentDate(item.importDate) < 0)) {
        //   set_dateWarning(true)
        //   notification.error({message:"Ngày nhập phải sau ngày hiện tại"})
        // }
        else{
          //console.log("data saved", data)
          onSaveData(data);    
        }
    }

    const createNewSteelTypes = async () => {
      let newSteelTypes = app.steelTypeMaster.filter(e => !!e.value.includes('create-new-'));
      newSteelTypes = newSteelTypes.map(e => {
        return {
          value: e.value.split("create-new-")[1],
          label: e.label
        };
      });
      await Promise.all(
        newSteelTypes.map(async e => {
          const requestData = {
            steelTypeName: e.label,
            steelTypeAcronym: e.value
          }
          try {
            await AxiosService.post(apis.Url_Api_Add_MasterData_SteelType, requestData)
          } catch (err) {
            console.log('createNewSteelTypes', err);
            notification.error({ error: err.message })
          }
        })
      )
    }

    const onSaveData = async (data) => {
      dispatch(appLoading(true))
      let requestData = {
        listImport : data.map(item => {
          let formatItem = {
            ...item,
            size: item.sizeThick+' × '+item.sizeLong,
            coilId: null,
            temWeight: Number(item.temWeight),
            netWeight: Number(item.temWeight)
          }

          if (!!item.steelType.includes('create-new-')) {
            formatItem = {
              ...formatItem,
              steelType: item.steelType.split('create-new-')[1]
            }
          }

          return formatItem;
        }),
        draft : "1", saveInStock : null
      }
      await createNewSteelTypes()
      AxiosService.post(apis.Url_Api_Post_ThemChiThiNhapKho, requestData)
      .then(response => {
        let {data} = response;
        if(!!data && data.message === "OK"){
          notification.success({ message: 'Lưu thành công'})
          set_data([])
          set_showWarning(false)
          set_dateWarning(false)
          // set_data([{...sampleInput, key: rid()}])
          requestGetNotiToken()
          
          history.push(paths.main_danh_sach_nhap_kho)
        }
        dispatch(appLoading(false))
      }).catch(err => {
        notification.error({ message: err.message})
        dispatch(appLoading(true))
        console.log(err)});
    }

    const requestGetNotiToken = () => {
      let dataRequest = {
        "lstRole" : [keys.userRoles.KhoTonCuon_Request]
      }
      AxiosService.post(apis.Url_Get_ListMessageTokenByRole, dataRequest)
        .then(response => {
          let { data } = response;
          if (!!data && data.message === "OK") {
            requestSendNoti(data.data[keys.userRoles.KhoTonCuon_Request])
          }else {
          }
        })
        .catch(err => console.log(err))
    }
  
    const requestSendNoti = (listToken = []) => {
      let dataRequest = {
        "subject" : "NSMP STORAGE",
        "content" : "NSMP STORAGE có danh sách nhập kho mới",
        "data" : {
           "message" : "nhập kho mới"
        },
        "registrationToken" : listToken.map(item => item.token)
    }
      AxiosService.post(apis.Url_Get_SendNotification, dataRequest)
        .then(response => {
          let { data } = response;
          console.log('send noti response',data);
        })
        .catch(err => console.log(err))
    }

    
    const onChangeInput = (value, item_index) =>{
        let new_data = data.map((item, index) => index === item_index ? {...item, ...value} : item  );
        set_data(new_data)

    }

    const onSearchSteelMaster = _.debounce(function (key, searchTerm) {
      const isExist = app.steelTypeMaster.find(e => e.label === searchTerm.trim());
   
      if (!isExist) {
       const oldSteelTypeMaster = app.steelTypeMaster.filter(e => !e.value.includes('create-new-'));

       // chỉ lấy những loại mới được thêm ở các row khác
       const addedSteelTypeMaster = app.steelTypeMaster.filter(e =>
        !!e.value.includes('create-new-') &&
        !!e.tableRow &&
        e.tableRow !== key
      );
       let newSteelTypeMaster = [
        ...oldSteelTypeMaster,
        // Lấy những loại mới được tạo ở các row khác
        ...app.steelTypeMaster.filter(e => !!e.value.includes('create-new-') && !!e.tableRow && e.tableRow !== key)
      ]

       if (!!searchTerm && searchTerm !== "") {  
         const newElement = generateNewTypeOfSteel({
           searchTerm,
           originalSteelMasterTypes: oldSteelTypeMaster,
           addedSteelTypeMaster
         });
         newSteelTypeMaster = !!newElement ? [...newSteelTypeMaster, { ...newElement, tableRow: key }] : [...newSteelTypeMaster];
       }
   
       dispatch(setAppProperties({
         steelTypeMaster: [...newSteelTypeMaster]
        }))
      }
   }, 100);

   const onSteelTypeInputChange = (key, inputData, index) => {
    // kiểm tra xem value đã tồn tại ở những loại mới đc thêm vào chưa
    const isExistInNewSteelTypes = app.steelTypeMaster.find(e => {
      return !!e.value.includes('create-new-')
        && !!e.tableRow
        && e.tableRow !== key
        && e.value === inputData.steelType;
    })
        // nếu người dùng chọn cái đã có sẵn thì xóa bỏ loại được thêm vào khi người dùng typing ở row hiện tại
    if (!inputData.steelType.includes('create-new-') || !!isExistInNewSteelTypes) {
      dispatch(setAppProperties({
        steelTypeMaster: [
          ...app.steelTypeMaster.filter(e => {
            // lấy những loại cũ và những loại mới ở row khác
            return  !e.value.includes('create-new-') ||
                    (!!e.value.includes('create-new-') && !!e.tableRow && e.tableRow !== key);
          })
        ]
      }))
    }
    onChangeInput(inputData, index);
   }

    const columns = [
        {
          title: 'Ngày nhập*',
          dataIndex: 'importDate',
          key: 'importDate',
          width: '150px',
          render: (text, item, index) =>
            <DatePicker
              format={"DD/MM/YYYY"}
              className={cn(style['filter-input'],(compareCurrentDate(item.importDate) < 0 && dateWarning ) && style['warning-input'])}
              placeholder='' 
              value={!!item.importDate && item.importDate !== "" ? dayjs(item.importDate, 'DD/MM/YYYY') : undefined}
              onChange={(date, dateString) => onChangeInput({importDate: dateString}, index)}/>,
        },
        // {
        //   title: 'Số cuộn',
        //   dataIndex: 'coilId',
        //   key: 'coilId',
        //   width: '100px',
        //   render: (text, item, index) =>  <Input value={item.coilId} disabled className={style['filter-input']} onChange = {({target})  => onChangeInput({coilId: target.value}, index)}/>,
        // },
        {
          title: 'Mác thép*',
          dataIndex: 'steelGrade',
          key: 'steelGrade',
          width:'140px',
          render: (text, item, index) =>  <Input value={item.steelGrade} className={cn(style['filter-input'], (item.steelGrade === "" && showWarning && !item.new) && style['warning-input'])} onChange = {({target})  => onChangeInput({steelGrade: target.value}, index)}/>,
        },
        {
            title: 'Chủng loại*',
            dataIndex: 'steelType',
            key: 'steelType',
            width: '150px',
            render: (text, item, index) =>  <Select
            className={cn(style['filter-select'], (item.steelType === "" && showWarning && !item.new) && style['warning-input'])}
            onChange = {(value)  => onSteelTypeInputChange(item.key, {steelType: value}, index)}
            // defaultValue={''}
            value={item.steelType}
            showSearch
            optionFilterProp="label"
            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
            options={app.steelTypeMaster.filter(item => !!item.value)}
            onSearch={text => onSearchSteelMaster(item.key, text)}
          />
          },
          {
            title: 'Mục đích sử dụng*',
            dataIndex: 'misaNameAcronym',
            width:'180px',
            key: 'misaNameAcronym',
            render: (text, item, index) =>  <Select
            className={cn(style['filter-select'],)}
            onChange = {(value)  => onChangeInput({misaNameAcronym: value}, index)}
            defaultValue={''}
            value={item.misaNameAcronym}
            showSearch
            optionFilterProp="label"
            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
            options={MISA_NAME_ACRONYM}
          />
          },
          {
            title: 'Dày x rộng(mm)*',
            dataIndex: 'size',
            // align:'center',
            key: 'size',
            width: '160px',
            render: (text, item, index) => <Space>
                 <Input  value={item.sizeThick} className={cn(style['filter-input'], (item.sizeThick === "" && showWarning && !item.new) && style['warning-input'])} onChange = {({target})  => onChangeInput({sizeThick: target.value.replace(/[^0-9.]/g, "")}, index)}/>
                 <div>×</div>
                 <Input value={item.sizeLong} className={cn(style['filter-input'], (item.sizeLong === "" && showWarning && !item.new) && style['warning-input'])} onChange = {({target})  => onChangeInput({sizeLong: target.value.replace(/[^0-9.]/g, "")}, index)}/>
            </Space>,
          },
          {
            title: 'Trọng lượng(kg)*',
            dataIndex: 'temWeight',
            key: 'temWeight',
            width: '170px',
            render: (text, item, index) =>  <Input value={item.temWeight} className={cn(style['filter-input'], (item.temWeight === "" && showWarning && !item.new) && style['warning-input'])} onChange = {({target})  => onChangeInput({temWeight: target.value.replace(/[^0-9.]/g, "")}, index)}/>,
          },
          {
            title: 'Loại hàng*',
            dataIndex: 'consignment',
            key: 'consignment',
            width:'150px',
            render: (text, item, index) =>  <Select
            className={cn(style['filter-select'], (item.consignment === "" && showWarning && !item.new) && style['warning-input'])}
            onChange = {(value)  => onChangeInput({consignment: value}, index)}
            defaultValue={''}
            value={item.consignment}
            showSearch
            optionFilterProp="label"
            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
            options={CONSIGNMENT}
          />
          },
          {
            title: 'Nhà cung cấp',
            dataIndex: 'providerName',
            key: 'providerName',
            width:'160px',
            render: (text, item, index) =>  <Input value={item.providerName} className={style['filter-input']} onChange = {({target})  => onChangeInput({providerName: target.value}, index)}/>,
          },
          {
            title: 'Nhà sản xuất',
            dataIndex: 'producerCode',
            key: 'producerCode',
            width:'160px',
            render: (text, item, index) =>  <Input value={item.producerCode} className={style['filter-input']} onChange = {({target})  => onChangeInput({producerCode: target.value}, index)}/>,
          },
          {
            title: 'Ghi chú',
            dataIndex: 'note',
            key: 'note',
            render: (text, item, index) =>  <Input value={item.note} className={style['filter-input']} onChange = {({target})  => onChangeInput({note: target.value}, index)}/>,
          },
          {
            title: '',
            dataIndex: 'value',
            key: 'value',
            width:'100px',
            fixed: 'right',
            render: (value, item, index) =>  <Space>
                <div className={style['table-button']} onClick={() => removeRow(index)}><DeleteOutlined/></div>
                <div className={style['table-button']} onClick={() => duplicateRow(index)}> <CopyOutlined /></div>
               
            </Space>,
          },
        
      ];

      const checkEmptyRecord = (record) => {
        console.log("Record", record)
        if(
          // record.coilId == '' ||
          record.temWeight == '' ||
          // record.providerName == '' ||
          record.sizeLong == '' ||
          record.sizeThick == '' ||
          record.steelGrade == '' ||
          record.steelType == '' ||
          record.consignment == ''
        ){return true}
        return false
      }

    return (
            <div className={style["sub-container"]}>
                <Flex  justify='space-between'>
                  <Flex align='center'>
                  <h3 style={{ color: 'black' }}>Thêm chỉ thị nhập</h3>
                    {/* <Select
                        className={style['filter-select']}
                        onChange={() => { }}
                        dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
                        defaultValue={'0'}
                        options={[
                            { value: '0', label: 'Kho Tôn Cuộn' },
                        ]}
                    /> */}
                    </Flex>
                    <Flex align='center'>
                      <Button className={style['filter-button']} style={{marginLeft: 40, minWidth: '80px'}} onClick={onPressSave}>Lưu</Button>
                      <Button className={style['filter-button']} style={{marginLeft: 10, minWidth: '80px'}} onClick={addRow}>Thêm</Button>
                    </Flex>
                </Flex>

                <Table columns={columns} dataSource={data} className={style['table']} rowKey="key"
                ref={table}
                scroll={{ y: data.length > 10 ? 580 : null, x: 1800 }}
                pagination={{ position: ['none', 'none'], pageSize: 500000 }} />
            </div>
      
    )

    
};

export default ThemMoiNhapKho;

const sampleInput = {
    
    // coilId : "",
    steelGrade : '',
    importDate : dayjs().format("DD/MM/YYYY"),
    steelType : "",
    misaNameAcronym: "SR",
    temWeight : "",
    netWeight : "",
    size : "",
    providerName : "",
    note : "",
    sizeThick: "",
    sizeLong: "",
    consignment:"",
    new: true
  }

